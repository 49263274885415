const ScheduleDemo = () => {
    return (
        <>
            <section className="hero is-small has-background-success-light p-6 mt-6">
                <div className="hero-body">
                    <p className="title is-1 has-text-black">
                        Schedule a Demo
                    </p>
                </div>
            </section>
            <section>
                <div className="columns">
                    <div className="column ">
                        <section className="section content">
                            <p className="">In the demo session, our product consultant will walk you through StoreHippo platform and answer your queries about the platform and how it can help launch and scale your business model.</p>
                            <hr />
                            <p>You will learn how you can:</p>
                            <ul >
                                <li>Build, manage and promote your online store</li>
                                <li>Create a global and omni-channel presence for your online business</li>
                                <li>Improve your business productivity with powerful tools</li>
                            </ul>
                        </section>
                    </div>
                    <div className="column is-5 p-6 mr-6 mb-6" style={{ marginTop: '-200px' }}>
                        <div className="box p-6">
                            <div className="field">
                                <label className="label">Name</label>
                                <div className="control">
                                    <input className="input" type="text" placeholder="" />
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Email</label>
                                <div className="control">
                                    <input className="input" type="email" placeholder="" value="" />
                                </div>
                                {/* <p className="help is-danger">This email is invalid</p> */}
                            </div>
                            <div className="field">
                                <label className="label">Company</label>
                                <div className="control">
                                    <input className="input" type="text" placeholder="" value="" />
                                </div>
                                {/* <p className="help is-success">This username is available</p> */}
                            </div>

                            <div className="field">
                                <label className="label">Phone</label>
                                <div className="field-body">
                                    <div className="field is-expanded">
                                        <div className="field has-addons">
                                            <p className="control">
                                                <input className="input" type="tel" size={2} placeholder="" />
                                            </p>
                                            <p className="control is-expanded">
                                                <input className="input" type="tel" placeholder="" />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Message</label>
                                <div className="control">
                                    <textarea className="textarea" placeholder=""></textarea>
                                </div>
                            </div>

                            <div className="field is-grouped is-grouped-centered">
                                <div className="control">
                                    <button className="button is-link">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
};

export default ScheduleDemo;