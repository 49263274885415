import { useState } from "react";

const CardSlider = () => {
    const [slide, setslide] = useState(1);
    return (
        <div className="section">
            <div className="has-text-centered mb-5">
                <p className="title is-3 is-spaced">What can you build with IG Commerce ?</p>
                <p className="subtitle is-6">Experience a full-featured development platform with beyond E-commerce capabilities.<br /> Get inspired by thousands of use cases.</p>
            </div>
            <div className={`tile is-ancestor ${slide === 1 ? '' : 'is-hidden'}`}>
                {/* <div className="tile is-flex-wrap-wrap"> */}
                <div className="tile is-4 is-parent">
                    <div className="card has-background-success-light">
                        {/* <div className="card-image">
                            <figure className="image is-256x256">
                                <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image" />
                            </figure>
                        </div> */}
                        <div className="card-content has-text-centered">
                            <div className="media">
                                {/* <div className="media-left">
                                    <figure className="image is-48x48">
                                        <img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image" />
                                    </figure>
                                </div> */}
                                <div className="media-content">
                                    <p className="title is-4 has-text-success-dark"> B2C Ecommerce</p>
                                    {/* <p className="subtitle is-6">@johnsmith</p> */}
                                </div>
                            </div>

                            <div className="content has-text-grey-dark">
                                Digitize your retail sales channels to unlock new growth opportunities. Get end-to-end  B2C ecommerce solutions to build and grow your business.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tile is-4 is-parent">
                    <div className="card has-background-success-light">
                        {/* <div className="card-image">
                            <figure className="image is-256x256">
                                <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image" />
                            </figure>
                        </div> */}
                        <div className="card-content has-text-centered">
                            <div className="media">
                                {/* <div className="media-left">
                                    <figure className="image is-48x48">
                                        <img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image" />
                                    </figure>
                                </div> */}
                                <div className="media-content">
                                    <p className="title is-4 has-text-success-dark"> B2C Ecommerce</p>
                                    {/* <p className="subtitle is-6">@johnsmith</p> */}
                                </div>
                            </div>

                            <div className="content has-text-grey-dark">
                                Digitize your retail sales channels to unlock new growth opportunities. Get end-to-end  B2C ecommerce solutions to build and grow your business.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tile is-4 is-parent">
                    <div className="card has-background-success-light">
                        {/* <div className="card-image">
                            <figure className="image is-256x256">
                                <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image" />
                            </figure>
                        </div> */}
                        <div className="card-content has-text-centered">
                            <div className="media">
                                {/* <div className="media-left">
                                    <figure className="image is-48x48">
                                        <img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image" />
                                    </figure>
                                </div> */}
                                <div className="media-content">
                                    <p className="title is-4 has-text-success-dark"> B2C Ecommerce</p>
                                    {/* <p className="subtitle is-6">@johnsmith</p> */}
                                </div>
                            </div>

                            <div className="content has-text-grey-dark">
                                Digitize your retail sales channels to unlock new growth opportunities. Get end-to-end  B2C ecommerce solutions to build and grow your business.
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </div>
            <div className={`tile is-ancestor ${slide === 2 ? '' : 'is-hidden'}`}>
                {/* <div className="tile is-flex-wrap-wrap"> */}
                <div className="tile is-4 is-parent">
                    <div className="card">
                        <div className="card-image">
                            <figure className="image is-256x256">
                                <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image" />
                            </figure>
                        </div>
                        <div className="card-content has-text-centered">
                            <div className="media">
                                {/* <div className="media-left">
                                    <figure className="image is-48x48">
                                        <img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image" />
                                    </figure>
                                </div> */}
                                <div className="media-content">
                                    <p className="title is-4">Multi-Seller Marketplaces</p>
                                    {/* <p className="subtitle is-6">@johnsmith</p> */}
                                </div>
                            </div>

                            <div className="content">
                                Comprehensive multi-seller marketplace for B2B and B2C e-commerce businesses. Simplified feature-rich dashboard to manage and automate your marketplace.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tile is-4 is-parent">
                    <div className="card">
                        <div className="card-image">
                            <figure className="image is-256x256">
                                <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image" />
                            </figure>
                        </div>
                        <div className="card-content has-text-centered">
                            <div className="media">
                                {/* <div className="media-left">
                                    <figure className="image is-48x48">
                                        <img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image" />
                                    </figure>
                                </div> */}
                                <div className="media-content">
                                    <p className="title is-4">Multi-Seller Marketplaces</p>
                                    {/* <p className="subtitle is-6">@johnsmith</p> */}
                                </div>
                            </div>

                            <div className="content">
                                Comprehensive multi-seller marketplace for B2B and B2C e-commerce businesses. Simplified feature-rich dashboard to manage and automate your marketplace.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tile is-4 is-parent">
                    <div className="card">
                        <div className="card-image">
                            <figure className="image is-256x256">
                                <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image" />
                            </figure>
                        </div>
                        <div className="card-content has-text-centered">
                            <div className="media">
                                {/* <div className="media-left">
                                    <figure className="image is-48x48">
                                        <img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image" />
                                    </figure>
                                </div> */}
                                <div className="media-content">
                                    <p className="title is-4">Multi-Seller Marketplaces</p>
                                    {/* <p className="subtitle is-6">@johnsmith</p> */}
                                </div>
                            </div>

                            <div className="content">
                                Comprehensive multi-seller marketplace for B2B and B2C e-commerce businesses. Simplified feature-rich dashboard to manage and automate your marketplace.
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </div>
            <div className={`tile is-ancestor ${slide === 3 ? '' : 'is-hidden'}`}>
                {/* <div className="tile is-flex-wrap-wrap"> */}
                <div className="tile is-4 is-parent">
                    <div className="card">
                        <div className="card-image">
                            <figure className="image is-256x256">
                                <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image" />
                            </figure>
                        </div>
                        <div className="card-content has-text-centered">
                            <div className="media">
                                {/* <div className="media-left">
                                    <figure className="image is-48x48">
                                        <img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image" />
                                    </figure>
                                </div> */}
                                <div className="media-content">
                                    <p className="title is-4">Multilingual Portals</p>
                                    {/* <p className="subtitle is-6">@johnsmith</p> */}
                                </div>
                            </div>

                            <div className="content">
                                Explore international markets with multilingual websites. Offer multi-currency payments and IP location-based pricing.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tile is-4 is-parent">
                    <div className="card">
                        <div className="card-image">
                            <figure className="image is-256x256">
                                <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image" />
                            </figure>
                        </div>
                        <div className="card-content has-text-centered">
                            <div className="media">
                                {/* <div className="media-left">
                                    <figure className="image is-48x48">
                                        <img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image" />
                                    </figure>
                                </div> */}
                                <div className="media-content">
                                    <p className="title is-4">Multilingual Portals</p>
                                    {/* <p className="subtitle is-6">@johnsmith</p> */}
                                </div>
                            </div>

                            <div className="content">
                                Explore international markets with multilingual websites. Offer multi-currency payments and IP location-based pricing.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tile is-4 is-parent">
                    <div className="card">
                        <div className="card-image">
                            <figure className="image is-256x256">
                                <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image" />
                            </figure>
                        </div>
                        <div className="card-content has-text-centered">
                            <div className="media">
                                {/* <div className="media-left">
                                    <figure className="image is-48x48">
                                        <img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image" />
                                    </figure>
                                </div> */}
                                <div className="media-content">
                                    <p className="title is-4">Multilingual Portals</p>
                                    {/* <p className="subtitle is-6">@johnsmith</p> */}
                                </div>
                            </div>

                            <div className="content">
                                Explore international markets with multilingual websites. Offer multi-currency payments and IP location-based pricing.
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </div>
            <div className="has-text-centered">
                <span className="dot" style={{ backgroundColor: slide === 1 ? '#5A5A5A' : '' }} onClick={() => setslide(1)}></span>
                <span className="dot" style={{ backgroundColor: slide === 2 ? '#5A5A5A' : '' }} onClick={() => setslide(2)}></span>
                <span className="dot" style={{ backgroundColor: slide === 3 ? '#5A5A5A' : '' }} onClick={() => setslide(3)}></span>
            </div>
        </div>
    );
};

export default CardSlider;