const Performance = () => {
    return (
        <div className="section">
            <div className="has-text-centered mb-5">
                <p className="title is-3 is-spaced has-text-success-dark">Unleash growth with next-gen ecommerce</p>
                <p className="subtitle is-6">Build future-ready ecommerce solutions that keep you ahead of the curve</p>
            </div>
            <div className="tile is-ancestor mt-3">
                <div className="tile is-parent has-text-centered">
                    <article className="tile is-child box has-background-primary-light">
                        <figure className="image is-64x64" style={{ margin: '0 auto' }}>
                            <img src="/img/performance_icons/agility1.png" />
                        </figure>
                        <div className="content mt-5">
                            <p className="title is-5 is-spaced"><b>Agility</b></p>
                            <p className="subtitle is-6">Stay ahead of trends with rapid adoption of technology</p>
                        </div>
                    </article>
                </div>
                <div className="tile is-parent has-text-centered">
                    <article className="tile is-child box has-background-primary-light">
                        <figure className="image is-64x64" style={{ margin: '0 auto' }}>
                            <img src="/img/performance_icons/flexibility1.png" />
                        </figure>
                        <div className="content mt-5">
                            <p className="title is-5 is-spaced"><b>Flexibility</b></p>
                            <p className="subtitle is-6">Customize ecommerce solutions quickly and easily for market demands</p>
                        </div>
                    </article>
                </div>

                <div className="tile is-parent has-text-centered">
                    <article className="tile is-child box has-background-primary-light">
                        <figure className="image is-64x64" style={{ margin: '0 auto' }}>
                            <img src="/img/performance_icons/integration1.png" />
                        </figure>
                        <div className="content mt-5">
                            <p className="title is-5 is-spaced"><b>Integrations</b></p>
                            <p className="subtitle is-6">120+ integrations to build out-of-box solutions for enterprise brands</p>
                        </div>
                    </article>
                </div>

                <div className="tile is-parent has-text-centered">
                    <article className="tile is-child box has-background-primary-light">
                        <figure className="image image is-64x64" style={{ margin: '0 auto' }}>
                            <img src="/img/performance_icons/time1.png" />
                        </figure>
                        <div className="content mt-5">
                            <p className="title is-5 is-spaced"><b>Faster time to market</b></p>
                            <p className="subtitle is-6">Roll out or pivot to your unique business model faster than your competition</p>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    );
};

export default Performance;