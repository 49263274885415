const MobileCommerce = () => {
    return (
        <div className="section">
            <div className="has-text-centered mb-5">
                <p className="title is-3 is-spaced has-text-success-dark">Ace your ecommerce game with mobile commerce</p>
                <p className="subtitle is-6">73% of ecommerce happens on mobile devices.<br /> Gear up your brand for the trillion-dollar M-commerce market with high-speed stores built on mobile-first architecture.</p>
            </div>
            <div className="tile is-ancestor mt-3">
                <div className="tile is-parent has-text-centered">
                    <article className="tile is-child box has-background-success-light">
                        <figure className="image is-64x64" style={{ margin: '0 auto' }}>
                            <img src="/img/mobile_commerce/ios_android1.png" />
                        </figure>
                        <div className="content mt-5">
                            {/* <p className="title is-4 is-spaced">Agility</p> */}
                            <p className="subtitle is-6 has-text-grey-darker">ZERO additional cost to build Android and iOS apps</p>
                        </div>
                    </article>
                </div>
                <div className="tile is-parent has-text-centered">
                    <article className="tile is-child box has-background-success-light">
                        <figure className="image is-64x64" style={{ margin: '0 auto' }}>
                            <img src="/img/mobile_commerce/spa1.png" />
                        </figure>
                        <div className="content mt-5">
                            {/* <p className="title is-4 is-spaced">Flexibility</p> */}
                            <p className="subtitle is-6 has-text-grey-darker">Quick loading pages built with SPA for seamless buying experience</p>
                        </div>
                    </article>
                </div>

                <div className="tile is-parent has-text-centered">
                    <article className="tile is-child box has-background-success-light">
                        <figure className="image is-64x64" style={{ margin: '0 auto' }}>
                            <img src="/img/mobile_commerce/pwa1.png" />
                        </figure>
                        <div className="content mt-5">
                            {/* <p className="title is-4 is-spaced">Integrations</p> */}
                            <p className="subtitle is-6 has-text-grey-darker">PWA stores that work as hybrid apps across devices</p>
                        </div>
                    </article>
                </div>

                <div className="tile is-parent has-text-centered">
                    <article className="tile is-child box has-background-success-light">
                        <figure className="image is-64x64" style={{ margin: '0 auto' }}>
                            <img src="/img/mobile_commerce/no_internet1.png" />
                        </figure>
                        <div className="content mt-5">
                            {/* <p className="title is-4 is-spaced">Faster time to market</p> */}
                            <p className="subtitle is-6 has-text-grey-darker">Seamless user-experience in poor or no internet zones</p>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    );
};

export default MobileCommerce;