import './App.css';
import 'bulma/css/bulma.min.css';
import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Header from "./components/header";
import Home from "./components/home";
import ScheduleDemo from "./components/schedule_demo";
import FreeTrial from "./components/free_trial";
import Footer from "./components/footer";
import Contact from "./components/contact_us";
import About from "./components/about";


function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="schedule" element={<ScheduleDemo />} />
        <Route path="trial" element={<FreeTrial />} />
        <Route path="contact" element={<Contact />} />
        <Route path="about" element={<About />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
