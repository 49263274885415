const About = () => {
    return (
        <div className="content">
            <div className="section mt-6">
                <p className="title is-3 is-spaced has-text-centered">About IG Commerce</p>
                <p className="subtitle is-3 has-text-centered">Leading Innovation Always</p>
                <p className="has-text-centered">IG Commerce  the #1 Omnichannel Enterprise Ecommerce Platform has been building innovative, tailor-made solutions<br /> for enterprises and retailers since 2014. With StoreHippo, brands can build disruptive ecommerce solutions, go-to-market<br /> in record time and unlock new growth opportunities across channels.</p>
            </div>
            <div className="section has-text-centered">
                <p className="title">Designed to mould to your business potential</p>
                <p>Every brand is unique and deserves to have a tailor-made solution aligned with its vision. We at StoreHippo are committed <br />to delivering unique solutions for brands to help them realize their true potential.</p>
                <div>
                    <p>StoreHippo’s mobile-ready ecommerce platform powered by cutting edge technology and pure headless-architecture<br /> helps brands overcome the limitations imposed by their traditional monolithic software. Go for no holds barred growth by<br /> creating memorable buyer experiences on all your sales channels. Break the barriers with ecommerce solutions designed<br /> for unparalleled scalability, flexibility, agility and reliability.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default About;