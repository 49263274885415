import { Link } from "react-router-dom";

const Header = () => {
    return (
        <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation" style={{backgroundColor: '#48c78e'}}>
            <div className="navbar-brand">
                <Link to='/' className="navbar-item">
                {/* <a className="navbar-item"> */}
                    {/* <img src="https://bulma.io/images/bulma-logo.png" width="112" height="28" /> */}
                    <img src="/img/logo/ig_logo.png" width="112" height="28" />
                {/* </a> */}
                </Link>

                <a role="button" className="navbar-burger is-active" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>

            <div id="navbar" className="navbar-menu">
                <div className="navbar-start">
                    {/* <a className="navbar-item"> */}
                        <Link to='/' className="navbar-item">Home</Link>
                    {/* </a> */}

                    <a className="navbar-item">
                        Documentation
                    </a>

                    <div className="navbar-item has-dropdown is-hoverable">
                        <a className="navbar-link is-arrowless">
                            More
                        </a>

                        <div className="navbar-dropdown">
                            {/* <a className="navbar-item has-text-dark">
                                About
                            </a> */}
                            <Link to='about' className="navbar-item has-text-dark">About</Link>
                            <a className="navbar-item has-text-dark">
                                Jobs
                            </a>
                            {/* <a className="navbar-item has-text-dark">
                                Contact
                            </a> */}
                            <Link to='contact' className="navbar-item has-text-dark">Contact</Link>
                        </div>
                    </div>
                </div>

                <div className="navbar-end">
                    <div className="navbar-item">
                        <div className="buttons">
                            <Link to='trial'>
                                <button className="button is-light">Free Trial</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Header;