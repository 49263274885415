const Contact = () => {
    return (
        <>
            <section className="hero is-small has-background-success-light p-6 mt-6">
                <div className="hero-body">
                    <p className="title is-1 has-text-black">
                        Get in Touch
                    </p>
                </div>
            </section>
            <section>
                <div className="columns">
                    <div className="column ">
                        <section className="section">
                            <div>
                                <p className="title is-5 has-text-black">US OFFICE</p>
                                <table width={'200px'} style={{ textAlign: 'right' }}>
                                    <tr>
                                        <th>Phone:</th>
                                        <td>+1 (972) 635-3497</td>
                                    </tr>
                                    <tr>
                                        <th>Fax:</th>
                                        <td>+1 (972) 584-6081</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="mt-5">
                                <p className="title is-5 has-text-black">DEVELOPMENT CENTER</p>
                                <p>Module No: 002/3, 003/1 Ground Floor,<br />
                                    Tidel Park Coimbatore Limited, <br />
                                    ELCOT-SEZ, Civil Aerodrome Post, <br />
                                    Coimbatore, Tamil Nadu, India – 641014</p>
                                <table className="mt-2" width={'270px'} style={{ textAlign: 'right' }}>
                                    <tr>
                                        <th>Phone:</th>
                                        <td>+91 (422) 4021200 / 2973447</td>
                                    </tr>
                                </table>

                            </div>
                        </section>
                    </div>
                    <div className="column is-5 p-6 mr-6 mb-6" style={{ marginTop: '-200px' }}>
                        <div className="box p-6">
                            <h1 className="title is-4 has-text-centered">Send us a Message</h1>
                            <div className="field">
                                <label className="label">Name</label>
                                <div className="control">
                                    <input className="input" type="text" placeholder="" />
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Email</label>
                                <div className="control">
                                    <input className="input" type="email" placeholder="" value="" />
                                </div>
                                {/* <p className="help is-danger">This email is invalid</p> */}
                            </div>
                            <div className="field">
                                <label className="label">Company</label>
                                <div className="control">
                                    <input className="input" type="text" placeholder="" value="" />
                                </div>
                                {/* <p className="help is-success">This username is available</p> */}
                            </div>

                            <div className="field">
                                <label className="label">Phone</label>
                                <div className="field-body">
                                    <div className="field is-expanded">
                                        <div className="field has-addons">
                                            <p className="control">
                                                <input className="input" type="tel" size={2} placeholder="" />
                                            </p>
                                            <p className="control is-expanded">
                                                <input className="input" type="tel" placeholder="" />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Message</label>
                                <div className="control">
                                    <textarea className="textarea" placeholder=""></textarea>
                                </div>
                            </div>

                            <div className="field is-grouped is-grouped-centered">
                                <div className="control">
                                    <button className="button is-link">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
};

export default Contact;