import { Link } from "react-router-dom";

const Footer = () => {
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    return (
        // <div className="columns px-6" style={{ backgroundColor: '#41356c', color: 'white' }}>
        // <div className="columns px-6 box" style={{ borderTop: 'thin solid lightgrey'}}>
        <div className="columns px-6 box has-background-success-light">
            <div className="column">
                <h1 className="title is-5 is-underlined" style={{ color: '#41356c', textUnderlineOffset: '8px' }}>Resources</h1>
                <ul style={{ lineHeight: '2' }}>
                    <li>
                        <a href="">
                            <span className="icon-text">
                                <span className="icon mr-4">
                                    <figure className="image is-24x24">
                                        <img src='/assets/img/footer_resources/blog.png' />
                                    </figure>
                                </span>
                                <span>Blog</span>
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="">
                            <span className="icon-text">
                                <span className="icon mr-4">
                                    <figure className="image is-24x24">
                                        <img src='/assets/img/footer_resources/events.png' />
                                    </figure>
                                </span>
                                <span>Events</span>
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="">
                            <span className="icon-text">
                                <span className="icon mr-4">
                                    <figure className="image is-24x24">
                                        <img src='/assets/img/footer_resources/about_us.png' />
                                    </figure>
                                </span>
                                <span>About Us</span>
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="column">
                <h1 className="title is-5 is-underlined" style={{ color: '#41356c', textUnderlineOffset: '8px' }}>Main Services</h1>
                <ul style={{ lineHeight: '2' }}>
                    <li>
                        <a href="https://www.facebook.com/Rannsolveinc/">
                            <span className="icon-text">
                                <span className="icon mr-4">
                                    <figure className="image is-24x24">
                                        <img src='/assets/img/main_services/software_app2.png' />
                                    </figure>
                                </span>
                                <Link style={{ color: '#41356c' }} to='software' onClick={scrollToTop}>Software Application</Link>
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/rannsolve">
                            <span className="icon-text">
                                <span className="icon mr-4">
                                    <figure className="image is-24x24">
                                        <img src='/assets/img/main_services/mobile_app.png' />
                                    </figure>
                                </span>
                                <Link style={{ color: '#41356c' }} to='mobile' onClick={scrollToTop}>Mobile Application</Link>
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/rannsolve-inc/">
                            <span className="icon-text">
                                <span className="icon mr-4">
                                    <figure className="image is-24x24">
                                        <img src='/assets/img/main_services/digital_market.png' />
                                    </figure>
                                </span>
                                <Link style={{ color: '#41356c' }} to='digital' onClick={scrollToTop}>Digital Marketing</Link>
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/rannsolveinc/">
                            <span className="icon-text">
                                <span className="icon mr-4">
                                    <figure className="image is-24x24">
                                        <img src='/assets/img/main_services/devops.png' />
                                    </figure>
                                </span>
                                <Link style={{ color: '#41356c' }} to='devops' onClick={scrollToTop}>Devops</Link>
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="column">
                <h1 className="title is-5 is-underlined" style={{ color: '#41356c', textUnderlineOffset: '8px' }}>Social Media</h1>
                <ul style={{ lineHeight: '2' }}>
                    <li>
                        <a href="https://www.facebook.com/Rannsolveinc/">
                            <span className="icon-text">
                                <span className="icon mr-4">
                                    <figure className="image is-24x24">
                                        <img src='/assets/img/social_media/fb.png' />
                                    </figure>
                                </span>
                                <span>Facebook</span>
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/rannsolve">
                            <span className="icon-text">
                                <span className="icon mr-4">
                                    <figure className="image is-24x24">
                                        <img src='/assets/img/social_media/twitter.png' />
                                    </figure>
                                </span>
                                <span>Twitter</span>
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/rannsolve-inc/">
                            <span className="icon-text">
                                <span className="icon mr-4">
                                    <figure className="image is-24x24">
                                        <img src='/assets/img/social_media/linkedin.png' />
                                    </figure>
                                </span>
                                <span>Linkedin</span>
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/rannsolveinc/">
                            <span className="icon-text">
                                <span className="icon mr-4">
                                    <figure className="image is-24x24">
                                        <img src='/assets/img/social_media/ig.png' />
                                    </figure>
                                </span>
                                <span>Instagram</span>
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="column">
                <h1 className="title is-5 is-underlined" style={{ color: '#41356c', textUnderlineOffset: '8px' }}>Contact Us</h1>
                <p>Module No: 002/3, 003/1 Ground Floor,<br />
                    Tidel Park Coimbatore Limited, <br />
                    ELCOT-SEZ, Civil Aerodrome Post, <br />
                    Coimbatore, Tamil Nadu, India – 641014</p>
                <table className="mt-2" width={'270px'} style={{ textAlign: 'right' }}>
                    <tr>
                        <th>Phone:</th>
                        <td>+91 (422) 4021200 / 2973447</td>
                    </tr>
                </table>
            </div>
        </div>
    );
};

export default Footer;