const FreeTrial = () => {
    return (
        <div className="columns is-centered">
            <div className="column is-5 p-6 mr-6 my-6">
                <div className="box p-6">
                    <h1 className="title has-text-centered">Sign Up</h1>
                    <h3 className="subtitle is-6 has-text-centered">Start your 14 days free trial</h3>
                    <div className="field">
                        <label className="label">Name</label>
                        <div className="control">
                            <input className="input" type="text" placeholder="" />
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">Email</label>
                        <div className="control">
                            <input className="input" type="email" placeholder="" value="" />
                        </div>
                        {/* <p className="help is-danger">This email is invalid</p> */}
                    </div>
                    <div className="field">
                        <label className="label">Company</label>
                        <div className="control">
                            <input className="input" type="text" placeholder="" value="" />
                        </div>
                        {/* <p className="help is-success">This username is available</p> */}
                    </div>

                    <div className="field">
                        <label className="label">Phone</label>
                        <div className="field-body">
                            <div className="field is-expanded">
                                <div className="field has-addons">
                                    <p className="control">
                                        <input className="input" type="tel" size={2} placeholder="" />
                                    </p>
                                    <p className="control is-expanded">
                                        <input className="input" type="tel" placeholder="" />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">Message</label>
                        <div className="control">
                            <textarea className="textarea" placeholder=""></textarea>
                        </div>
                    </div>

                    <div className="field is-grouped is-grouped-centered">
                        <div className="control">
                            <button className="button is-link">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FreeTrial;