import Hero from "./hero";
import CardSlider from "./cardSlider";
import Performance from "./performance_info";
import MobileCommerce from "./mobile_commerce_info";

const Home = () => {
    return ( 
        <>
        <Hero />
        <CardSlider />
        <Performance />
        <MobileCommerce />
        </>
     );
}

export default Home;
