import { Link } from "react-router-dom";


const Hero = () => {
    return (
        <section className="hero is-medium is-success p-6">
            <div className="hero-body has-text-centered ">
                <p className="title is-1 has-text-black">
                    Most Flexible Ecommerce Platform
                </p>
                <p className="subtitle has-text-black">
                    Designed for diverse B2B, B2C and B2B2C business models
                </p>
                <div className="buttons is-centered">
                    <Link to='schedule'>
                        <button className="button is-success is-light" style={{ width: '200px' }}>
                            SCHEDULE A DEMO
                        </button>
                    </Link>
                    <Link to='trial'>
                        <button className="button is-success is-light" style={{ width: '200px' }}>
                            FREE TRIAL
                        </button>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default Hero;